import { memo, useState } from 'react';

import { useEffect } from 'react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import TableDWidget from './subComponents/tableDWidget';
import TableCWidget from './subComponents/tableCWidget';
import NumberWidget from './subComponents/numberWidget';
import GeneralisedGraphWidget from './subComponents/generalisedGraphWidget';
import axios from 'axios';

import { widgetDataInterface } from '../../Interfaces/widgetInterfaces';
import { timeFrame } from '../../Interfaces/dashboardPageInterfaces';
import { isAbsoluteURL, isRelativeURLWithoutSlash } from '../../utilities/helpers';
import { getWidgetData } from '../../services/dashboardPage';
import { Box } from '@mui/material';
import { add530Hours } from '../../utils/dashboard.utils';
import NumberWidget2 from './subComponents/RangeNumberWidget';
import FlocDetectorVisualizationChart from '../FlocDetector/FlocDetectorVisualizationChart';

SwiperCore.use([Navigation, Pagination, Autoplay]);

interface standaloneGeneralisedWidgetProps {
  widgetStructure?: any;
  timeFrame: timeFrame | null;
  refresh: boolean;
  plantId: string;
  headerType?: string | null;
  isConfigComplete: boolean;
}

const StandaloneGeneralisedWidget = memo((props: standaloneGeneralisedWidgetProps) => {
  const {
    widgetStructure,
    timeFrame,
    plantId,
    refresh,
    headerType = 'normal',
    isConfigComplete = false,
  } = props;
  const [widgetData, setWidgetData] = useState<any>(null);
  const [flocData, setFlocData] = useState(widgetStructure);

  // const [refresh2, setRefresh2] = useState(false);
  const [loading, setLoading] = useState(false);
  //@ts-ignore
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    const { startDate, endDate } = add530Hours(timeFrame?.startDate, timeFrame?.endDate);
    setIsError(false);
    setLoading(true);
    // debugger

    // plantId: 6704fdf23d686a58dde29820
    // widgetId: 673b2a859a11e04319ed1885
    // startDate: 2024 - 10 - 13T00:00:00.000Z
    // endDate: 2024 - 10 - 17T00:00:00.000Z
    // granularity: hours
    // timeRangeType: absolute
    // parent: dashboard
    // clock: off
    // selectedChart: undefined
    // widgetType: undefined
    // timeRange: null
    // showAllSample: null
    getWidgetData({ plantId, widgetStructure, startDate, endDate })
      .then((widgetData: any) => {
        setWidgetData(widgetData);
        setLoading(false);
      })

      .catch(err => {
        if (axios.isCancel(err)) {
        } else {
          setIsError(true);
        }
      });
  }, [widgetStructure, refresh, isConfigComplete]);

  useEffect(() => {
    if (
      widgetData &&
      widgetData.metricsData.lineGraph &&
      widgetData.metricsData.lineGraph?.length > 0
    ) {
      setFlocData(widgetData.metricsData.lineGraph);
    } else {
      setFlocData(null);
    }
  }, [widgetData]);

  let linkOnWidget = widgetData?.moreInfo || '';
  let showLinkIcon = true;

  if (linkOnWidget === 'link') {
    showLinkIcon = false;
  } else {
    if (!isAbsoluteURL(linkOnWidget)) {
      const location = window.location.origin;
      if (isRelativeURLWithoutSlash(linkOnWidget)) {
        linkOnWidget = `${location}/${linkOnWidget}`;
      } else {
        linkOnWidget = `${location}${linkOnWidget}`;
      }
    }
  }
  const setExpandedViewPopup = () => {};

  return (
    <Box sx={{ width: 'inherit', height: 'inherit' }}>
      {/* <> */}
      {widgetStructure?.widgetType === 'graph' && widgetData !== null ? (
        <GeneralisedGraphWidget
          widgetStructure={widgetStructure}
          widgetData={widgetData}
          setExpandedViewPopup={setExpandedViewPopup}
          isLoading={loading}
          headerType={headerType || 'normal'}
          enableZoom={false}
          viewEveryNthTick={2}
          ticksColor="light"
          showLinkIcon={showLinkIcon}
          linkOnWidget={linkOnWidget}
          type={'standalone'}
        />
      ) : null}

      {widgetStructure.widgetType === 'tableC' && widgetData !== null ? (
        <TableCWidget
          widgetStructure={widgetStructure}
          widgetData={widgetData}
          setExpandedViewPopup={setExpandedViewPopup}
          isLoading={loading}
          headerType={headerType || 'normal'}
          showLinkIcon={showLinkIcon}
          linkOnWidget={linkOnWidget}
        />
      ) : null}

      {widgetStructure.widgetType === 'tableD' && widgetData !== null ? (
        <TableDWidget
          widgetStructure={widgetStructure}
          widgetData={widgetData}
          setExpandedViewPopup={setExpandedViewPopup}
          isLoading={loading}
          headerType={headerType || 'normal'}
          showLinkIcon={showLinkIcon}
          linkOnWidget={linkOnWidget}
        />
      ) : null}

      {widgetStructure.widgetType === 'number' && widgetData !== null ? (
        <NumberWidget
          widgetStructure={widgetStructure}
          widgetData={widgetData}
          isLoading={loading}
          showLinkIcon={showLinkIcon}
          linkOnWidget={linkOnWidget}
          background={widgetData.background || '#F27F0C'}
        />
      ) : null}
      {(widgetStructure.widgetType === 'number2' || widgetStructure.widgetType === 'NumberGauge') &&
      widgetData !== null ? (
        <NumberWidget2
          widgetStructure={widgetStructure}
          widgetData={widgetData}
          isLoading={loading}
          showLinkIcon={showLinkIcon}
          linkOnWidget={linkOnWidget}
          theme={widgetStructure.widgetLook?.theme}
        />
      ) : null}

      {widgetStructure.widgetType === 'flocDetector' && widgetData !== null ? (
        <FlocDetectorVisualizationChart
          widgetData={widgetData}
          headerType="normal"
          enableZoom={false}
          viewEveryNthTick={2}
          ticksColor="light"
          timeFrame={timeFrame}
          customTimeRangeSettings={timeFrame}
          // setTimeFrame={setTimeFrame}
          // handleClockClick={handleClockClick}
          refresh={refresh}
          flocData={flocData}
          // setFlocData={setFlocData}
          widgetId={widgetData?._id}
          plantId={widgetData?.plantId || null}
        />
      ) : null}

      {/* </> */}
    </Box>
  );
});

export default StandaloneGeneralisedWidget;
