import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState } from 'react';
import { OtherSensor } from '../../Interfaces/PlantConfigurationInterface';
import { PlantConfigStyle } from '../plantConfiguration/PlantConfigStyle';
import { Primary } from '../../core-components/theme/color-palette/colors';

interface IProps {
  sensor: OtherSensor;
  index: number;
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setEquipmentIndex: any;
  setEquipmentName: React.Dispatch<React.SetStateAction<string>>;
  setEquipment: React.Dispatch<React.SetStateAction<string>>;
  updateOtherSensorTag: Function;
}

const styles = {
  headingStyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0.6rem',
    gap: '1.2rem',
  },

  pairBox: {
    width: 'calc(47% - 1rem)',
    '@media (max-width: 600px)': { width: '100%' },
    padding: '1rem',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    marginRight: '1rem',
    marginBottom: '1rem',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: '1rem',
  },
  focusBox: {
    padding: '1.5rem',
    border: `2px solid ${Primary[400]}`,
    borderRadius: '12px',
    backgroundColor: Primary[100],
    marginBottom: '1.5rem',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: Primary[200],
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
    },
  },
};

const FlocDetector: React.FC<IProps> = ({
  sensor,
  index,
  setShowDeleteModal,
  setEquipmentIndex,
  setEquipmentName,
  setEquipment,
  updateOtherSensorTag,
}) => {
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  const handleExpandClick = () => {
    setAccordionExpanded(!accordionExpanded);
  };

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      {/* Accordion Header */}
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={handleExpandClick}
        sx={{ background: 'none' }}
      >
        <Box sx={styles.headingStyle}>
          <Typography variant="h4">
            {sensor.name} {sensor.count}
          </Typography>
        </Box>
      </AccordionSummary>

      {/* Accordion Content */}
      <AccordionDetails>
        <Box>
          {/* Render 'Floc Detector Special Tag' in one row */}
          {sensor.sensors
            .filter(sensorItem => sensorItem.sensorName === 'Floc Detector Special Tag')
            .map((sensorItem, sensorIndex) => (
              <Box sx={{ ...styles.row }} key={sensorIndex}>
                <Box sx={{ ...styles.pairBox, ...styles.focusBox }} style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={sensorItem.sensorName}
                    value={sensorItem.sensorTag || ''}
                    onChange={e =>
                      updateOtherSensorTag(index, e.target.value, 'sensorTag', sensorIndex)
                    }
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Nickname"
                    value={sensorItem.sensorNickName || ''}
                    onChange={e =>
                      updateOtherSensorTag(index, e.target.value, 'sensorNickName', sensorIndex)
                    }
                    style={{ marginTop: '0.5rem' }}
                  />
                </Box>
              </Box>
            ))}

          {/* Render other sensors in pairs */}
          <Box sx={styles.row}>
            {sensor.sensors
              .filter(sensorItem => sensorItem.sensorName !== 'Floc Detector Special Tag')
              .map((sensorItem, sensorIndex, arr) => {
                // Skip every second sensor for pairing
                if (sensorIndex % 2 === 0 && sensorIndex + 1 < arr.length) {
                  const nextSensor = arr[sensorIndex + 1];
                  return (
                    <React.Fragment key={sensorIndex}>
                      {/* Left Pair */}
                      <Box sx={styles.pairBox}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label={sensorItem.sensorName}
                          value={sensorItem.sensorTag || ''}
                          onChange={e =>
                            updateOtherSensorTag(index, e.target.value, 'sensorTag', sensorIndex)
                          }
                        />
                        <TextField
                          fullWidth
                          variant="outlined"
                          label={`${sensorItem.sensorName} Nickname`}
                          value={sensorItem.sensorNickName || ''}
                          onChange={e =>
                            updateOtherSensorTag(
                              index,
                              e.target.value,
                              'sensorNickName',
                              sensorIndex
                            )
                          }
                          style={{ marginTop: '0.5rem' }}
                        />
                      </Box>

                      {/* Right Pair */}
                      <Box sx={styles.pairBox}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label={nextSensor.sensorName}
                          value={nextSensor.sensorTag || ''}
                          onChange={e =>
                            updateOtherSensorTag(
                              index,
                              e.target.value,
                              'sensorTag',
                              sensorIndex + 1
                            )
                          }
                        />
                        <TextField
                          fullWidth
                          variant="outlined"
                          label={`${nextSensor.sensorName} Nickname`}
                          value={nextSensor.sensorNickName || ''}
                          onChange={e =>
                            updateOtherSensorTag(
                              index,
                              e.target.value,
                              'sensorNickName',
                              sensorIndex + 1
                            )
                          }
                          style={{ marginTop: '0.5rem' }}
                        />
                      </Box>
                    </React.Fragment>
                  );
                }

                // Handle the last unpaired sensor
                if (sensorIndex % 2 === 0 && sensorIndex + 1 >= arr.length) {
                  return (
                    <Box sx={styles.pairBox} key={sensorIndex}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label={sensorItem.sensorName}
                        value={sensorItem.sensorTag || ''}
                        onChange={e =>
                          updateOtherSensorTag(index, e.target.value, 'sensorTag', sensorIndex)
                        }
                      />
                      <TextField
                        fullWidth
                        variant="outlined"
                        label={`${sensorItem.sensorName} Nickname`}
                        value={sensorItem.sensorNickName || ''}
                        onChange={e =>
                          updateOtherSensorTag(index, e.target.value, 'sensorNickName', sensorIndex)
                        }
                        style={{ marginTop: '0.5rem' }}
                      />
                    </Box>
                  );
                }

                return null;
              })}
          </Box>
        </Box>

        {/* Delete Button */}
        <Box m={0} p={0} textAlign="center" sx={{ margin: '0px', padding: '0px' }}>
          <Button
            variant="contained"
            style={PlantConfigStyle.deleteButton}
            onClick={() => {
              setShowDeleteModal(true);
              setEquipmentIndex(index);
              setEquipmentName(`${sensor.name} ${sensor.count}`);
              setEquipment('Other Sensors');
            }}
          >
            <DeleteIcon style={PlantConfigStyle.deleteIcon} />
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default FlocDetector;
