import { Neutrals, Primary } from "../core-components/theme/color-palette/colors";

const globalStyles = {
    backgrounds: {
        paaniBlue: "rgb(97,152,201)",
        darkBlueTheme: "#193458",
    },

    buttons: {
        black: {
            background: "black",
            color: "#ddd",
        },
        paaniBlue: {
            background: "rgb(97,152,201)",
        },
        disabled: {
            background: "#999",
            color: "#white",
        },
    },

    color: {
        paaniBlue: "rgb(97,152,201)",
        darkBlueTheme: "#193458",
    }
};

const ButtonStyle = {
    confirmButtonStyles: {
        borderRadius: '4px',
        backgroundColor: `${Primary[500]}`,
        boxShadow: 'none',
        ':hover': { boxShadow: 'none', backgroundColor: `${Primary[500]}` },
    },
    outlineButtonStyles: {
        border: `1px solid ${Neutrals[500]}`,
        borderRadius: '4px',
        backgroundColor: 'white',
        color: `${Neutrals[500]}`,
        boxShadow: 'none',
        // minWidth: '50%',
        ':hover': { background: 'none', boxShadow: 'none' },
    }
}

export { globalStyles, ButtonStyle };
