import axios from 'axios';
import { toast } from 'react-toastify';
import { fetchFlocDetectorImgDetails, saveFlocDetectorImgDetails } from '../dashboardPage';
import { countWords } from '../../components/FlocDetector/Helper';
import { Chart as ChartJS } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
const userId = localStorage.getItem('userId');

export const handleNextSample = (
  currentSampleIndex: number,
  sampleKeys: string[],
  currentDateIndex: number,
  flocData: any[],
  setCurrentSampleIndex: React.Dispatch<React.SetStateAction<number>>,
  setCurrentDateIndex: React.Dispatch<React.SetStateAction<number>>
) => {
  if (currentSampleIndex < sampleKeys.length - 1) {
    setCurrentSampleIndex(prev => prev + 1);
  } else if (currentDateIndex < flocData?.length - 1) {
    setCurrentDateIndex(prev => prev + 1);
    setCurrentSampleIndex(0);
  }
};

export const handlePreviousSample = (
  currentSampleIndex: number,
  currentDateIndex: number,
  flocData: any[],
  setCurrentSampleIndex: React.Dispatch<React.SetStateAction<number>>,
  setCurrentDateIndex: React.Dispatch<React.SetStateAction<number>>
) => {
  if (currentSampleIndex > 0) {
    setCurrentSampleIndex(prev => prev - 1);
  } else if (currentDateIndex > 0) {
    setCurrentDateIndex(prev => prev - 1);
    const previousDateSamples = Object.keys(flocData[currentDateIndex - 1]?.samples || {}).length;
    setCurrentSampleIndex(previousDateSamples - 1);
  }
};

export const handleSelectedGraphType = (
  value: string,
  setSelectedTypeGraph: React.Dispatch<React.SetStateAction<string>>
) => {
  setSelectedTypeGraph(value);
};

export const handleClickFlocOpen = (
  imageId: any,
  setImgId: React.Dispatch<React.SetStateAction<any>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  fetchFlocImgData: (imageId: any) => void
) => {
  setImgId(imageId);
  setOpen(true);
  fetchFlocImgData(imageId);
};

export const fetchFlocImgData = async (
  imageId: string,
  setImageDetails: React.Dispatch<React.SetStateAction<any>>,
  setAllComments: React.Dispatch<React.SetStateAction<any>>
) => {
  try {
    const data = await fetchFlocDetectorImgDetails({ imageId });
    setImageDetails(data.imageDetails);
    setAllComments(data.comments);
  } catch (err) {
    if (!axios.isCancel(err)) {
      console.error(err);
    }
  }
};

export const saveFlocImgData = async (
  eventId: string,
  imageId: any,
  title: string,
  comment: string
) => {
  try {
    const data = await saveFlocDetectorImgDetails({
      eventId,
      userId,
      imageId,
      title,
      comment,
    });
    if (data) {
      toast.success('Commented!');
    }
  } catch (err) {
    if (!axios.isCancel(err)) {
      toast.error('Error saving comment!');
      console.error(err);
    }
  }
};

export const handleFlocClose = (setOpen: React.Dispatch<React.SetStateAction<boolean>>) => {
  return () => {
    setOpen(false);
  };
};

export const handleClick = (
  event: React.MouseEvent<HTMLElement>,
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
) => {
  setAnchorEl(event.currentTarget);
};

export const handleCloseComment = (
  event: React.MouseEvent<HTMLElement>,
  value: any,
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
  setCommentDialogueHeaderText: React.Dispatch<React.SetStateAction<string>>,
  setAnchorElComment: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (value === 'backdropClick') {
    setAnchorEl(null);
    return;
  }
  setCommentDialogueHeaderText(value);
  setAnchorEl(null);
  setAnchorElComment(event.currentTarget);
  setOpen(false);
};

export const handleCloseCommentDialogue = (
  setAnchorElComment: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setAddComment: React.Dispatch<React.SetStateAction<{ title: string; comment: string }>>,
  setErrorTitle: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorComment: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setAnchorElComment(null);
  setOpen(true);
  setAddComment({ title: '', comment: '' });
  setErrorTitle(false);
  setErrorComment(false);
};

export const handleCloseCommentDialogueOutside = (
  setAnchorElComment: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setAddComment: React.Dispatch<React.SetStateAction<{ title: string; comment: string }>>,
  setErrorTitle: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorComment: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setAnchorElComment(null);
  setOpen(false);
  setAddComment({ title: '', comment: '' });
  setErrorTitle(false);
  setErrorComment(false);
};

export const handleOnChangeComment = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  addComment: { title: string; comment: string },
  setAddComment: React.Dispatch<React.SetStateAction<{ title: string; comment: string }>>
) => {
  const name = e.target.name;
  const value = e.target.value;
  setAddComment({ ...addComment, [name]: value });
};

export const handleAddComment = (
  addComment: { title: string; comment: string },
  setErrorTitle: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorComment: React.Dispatch<React.SetStateAction<boolean>>,
  setAllComments: React.Dispatch<React.SetStateAction<any[]>>,
  setAddComment: React.Dispatch<React.SetStateAction<{ title: string; comment: string }>>,
  allComments: any[],
  flocData: any[],
  currentSampleIndex: number,
  imgId: any,
  handleCloseCommentDialogue: () => void
) => {
  const { title, comment } = addComment;

  // Validation
  let valid = true;
  if (title.length < 4 || title.length > 20) {
    setErrorTitle(true);
    valid = false;
  } else {
    setErrorTitle(false);
  }

  if (countWords(comment) < 5) {
    setErrorComment(true);
    valid = false;
  } else {
    setErrorComment(false);
  }

  if (!valid) {
    return;
  }

  setAllComments([...allComments, addComment]);
  setAddComment({ title: '', comment: '' });

  // Save comment for the specific sample
  saveFlocImgData(
    flocData[currentSampleIndex]?.eventId, // Directly accessing the sample by index
    imgId,
    title,
    comment
  );

  handleCloseCommentDialogue();
};

export const handleClickOpenExpandedView = (
  setOpenExpandedView: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setOpenExpandedView(true);
  ChartJS.register(zoomPlugin);
};

export const handleCloseExpandedView = (
  setOpenExpandedView: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setOpenExpandedView(false);
  ChartJS.unregister(zoomPlugin);
};

export const formatTime = (time: string | Date): string => {
  const date = new Date(time);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const formatFlocTitle = (input: string) => {
  try {
    return input.replace(/([a-zA-Z]+)(\d+)/, (match, p1, p2) => {
      return p1.charAt(0).toUpperCase() + p1.slice(1) + ' ' + p2;
    });
  } catch (err) {
    return input;
  }
};
