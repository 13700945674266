import { SAVE_FORMULA } from '../constants/apiPathContants';
import axiosInstance from '../constants/axiosInstance';
import { formulaDataForPlant } from '../Interfaces/formulaConfigInterfaces';
import { formulaDataForWorkspace } from '../Interfaces/workspaceFormulaConfigInterface';

interface GetFormulaListPayload {
  formula: string,
  pageNumber: number,
  limit: number,
  workspaceId?: string,
  plantIds?: string[]
}

interface getFormulaDetailsProps {
  formulaId: string | undefined
}

const checkAndSetFormulas = async (plantId: string, formulaDataForPlant: formulaDataForPlant) => {
  try {
    const { data } = await axiosInstance().post(
      `/plantManagement/checkAndSetFormulas?id=${plantId}`,
      formulaDataForPlant
    );
    console.log(data);
    return data;
  } catch (err) {
    console.error('checkAndSetFormulas api Failed');
  }
};

const getCustomFormulasList = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/plantManagement/getCustomFormulasList?id=${plantId}`
    );
    console.log(data);
    return data.data;
  } catch (err) {
    console.error('checkAndSetFormulas api Failed');
  }
};
const getCustomFormulaListForWorkspace = async (workspaceId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/plantManagement/getCustomFormulasListForWorkspace?id=${workspaceId}`
    );
    console.log(data);
    return data.data;
  } catch (err) {
    console.error('checkAndSetFormulas api Failed');
  }
};
const checkAndSetWorkspaceFormulas = async (
  workspaceId: string,
  formulaDataForWorkspace: formulaDataForWorkspace
) => {
  try {
    const { data } = await axiosInstance().post(
      `/plantManagement/checkAndSetWorkspaceFormulas?id=${workspaceId}`,
      formulaDataForWorkspace
    );
    console.log(data);
    return data;
  } catch (err) {
    console.error('checkAndSetFormulas api Failed');
  }
};

const getFormulaList = async (payload: GetFormulaListPayload) => {
  try {
    const { data } = await axiosInstance().post(
      `/formula/listOfFormulas`,
      payload
    );
    return data.data;
  } catch (err) {
    console.error('Formula Listing api Error');
    throw err;
  }
};

const deleteFormula = async (formulaId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `formula/deleteFormula`,
      {
        formulaId
      }
    );
    return data.data;
  } catch (err) {
    console.error('Formula Listing api Error');
    throw err;
  }
};


const saveFormula = async (payload: any) => {
  try {
    const { data } = await axiosInstance().post(
      SAVE_FORMULA,
      payload
    );
    return data.data;
  } catch (err) {
    throw err;
  }
};


const getFormulaDetails = async (payload: getFormulaDetailsProps) => {
  try {
    const { data } = await axiosInstance().get(
      `/formula/getFormulaDetails`,
      {params: payload}
    );
    return data.data;
  } catch (err) {
    console.error('Formula Listing api Error');
    return err;
  }
};


export {
  checkAndSetFormulas,
  getCustomFormulasList,
  getCustomFormulaListForWorkspace,
  checkAndSetWorkspaceFormulas,
  getFormulaList,
  deleteFormula,
  saveFormula,
  getFormulaDetails
};
