import { FONT_NAME } from '../../core-components/theme/theme';

const PlantConfigStyle = {
  loadingState: {
    width: '100%',
    height: '100vh',
    display: 'grid',
    placeItems: 'center',
  },
  configFormWrapper: {
    fontFamily: `${FONT_NAME}`,
    fontSize: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1.2rem',
  },
  deleteButton: {
    color: 'white',
    margin: '50px',
    marginTop: '20px',
    marginBottom: 'auto',
  },
  deleteIcon: {
    fontSize: '2rem',
  },
  equipmentTextField: {
    margin: '0.5rem 0',
    maxWidth: '200px',
  },
  inputLabel: {
    backgroundColor: 'white',
  },
  selectedOptions: {
    backgroundColor: '#dddddd',
    border: 'solid 0.1rem #dddddd',
    borderRadius: '0.8rem',
    padding: '0.1rem 0.8rem',
    marginTop: '0.2rem',
    marginRight: '0.2rem',
  },
};

export { PlantConfigStyle };
