import { createContext, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Dayjs } from 'dayjs';

import { Stack, ThemeProvider, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { PrimaryButton } from '../../../styles/global/components/dpButtons';
import dpTheme from '../../../styles/theme/dpTheme';
import { GRAPH_TYPE, WIDGET_TYPE } from '../../../constants/constants';
import { calculateTimeFrame } from '../../../utilities/helpers';
import { shortcutsItems } from './constants';
import useIsTablet from '../../../utilities/customHooks/useIsTablet';
import useIsDesktop from '../../../utilities/customHooks/useIsDesktop';
import useIsMobile from '../../../utilities/customHooks/useIsMobile';
import StaticDateTimePickerComponent from '../../../globalComponents/dateAndTime/StaticDateTimePickerComponent';

const breakpoints = {
  mobile: '@media (max-width: 600px)',
  tablet: '@media (min-width: 600px) and (max-width: 1024px)',
};

//Styles
const DateAndTimeStyles = {
  DateRangeStyle: {
    '.MuiPickersLayout-shortcuts': {
      gridRow: '1',
      gridColumn: '1/3',
      display: 'flex',
      maxWidth: '925px',
      overflowX: 'auto', // Enable horizontal scrolling
      scrollbarWidth: 'thin', // Firefox scrollbar width
      scrollbarColor: 'transparent transparent', // Firefox scrollbar color
      msOverflowStyle: 'none', // Hide scrollbar in IE and Edge
    },
    '.MuiStack-root': {
      gridRow: '2',
      gridColumn: '1/3',
      paddingBottom: breakpoints.mobile ? '0px' : '32px',
    },
    '.MuiPickersLayout-contentWrapper': {},
    '.MuiPickersLayout-shortcuts::-webkit-scrollbar': {
      display: 'none',
    },
    '.MuiListItem-root': {
      width: 'auto',
    },
    '.Mui-selected.MuiDateRangePickerDay-day': {
      backgroundColor: '#193458',
    },
    '.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
      backgroundColor: '#D8E7FD',
    },
    '.MuiDateRangePickerDay-hiddenDayFiller': {
      backgroundColor: 'white !important',
    },
    '.MuiChip-clickableColorDefault': {
      // backgroundColor: "#006582",
    },
    [breakpoints.tablet]: {
      '.MuiPickersLayout-shortcuts': {
        maxWidth: '710px',
      },
      '.MuiPickersLayout-contentWrapper': {
        gridRow: '3',
        gridColumn: '1',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    [breakpoints.mobile]: {
      '.MuiPickersLayout-shortcuts': {
        maxWidth: '320px',
      },
      '.MuiPickersLayout-contentWrapper': {
        gridRow: '3',
        gridColumn: '1',
      },
    },
  },
  PositiveButtonStyle: {
    height: '42px',
    padding: '16px 40px',
    backgroundColor: '#3271A9',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#265d85',
    },
    flex: '1 0 0',
  },
  NegativeButtonStyle: {
    height: '42px',
    padding: '16px 40px',
    backgroundColor: '#FFFFFF',
    color: '#8B8B8B',
    border: '1px solid rgba(139, 139, 139, 0.30)',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
    flex: '1 0 0',
  },
};

const DateRangeContext = createContext<any>({});
const useDateRange = () => {
  return useContext(DateRangeContext);
};

const LeftToolbar = () => {
  const {
    selectedDateRange,
    setSelectedDateRange,
    timeFrom,
    setTimeFrom,
    timeTo,
    setTimeTo,
    combineDateWithTime,
  } = useDateRange();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  return (
    <Stack direction={isMobile ? 'column' : 'row'} gap={4} pb={4}>
      <Stack direction="column" flexWrap="wrap" gap={4}>
        {isTablet && (
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap="4rem">
            <Stack direction="column">
              <span
                style={{
                  color: '#747474',
                  fontSize: '12px',
                  fontWeight: '400',
                }}
              >
                Select Date Range
              </span>
              <Stack direction="row" display="flex" alignItems="center">
                <span
                  style={{
                    fontSize: '24px',
                    fontWeight: '500',
                    marginRight: '5px',
                  }}
                >
                  {selectedDateRange[0] !== null
                    ? selectedDateRange[0].format('MMM D')
                    : 'Start Date'}
                </span>{' '}
                -
                <span
                  style={{
                    fontSize: '24px',
                    fontWeight: '500',
                    marginLeft: '5px',
                  }}
                >
                  {selectedDateRange[1] !== null
                    ? selectedDateRange[1].format('MMM D')
                    : 'End Date'}
                </span>
              </Stack>
            </Stack>
            <Stack direction="row" gap={3}>
              <TimePicker
                label="Start Time"
                ampm={false}
                value={timeFrom}
                timeSteps={{ minutes: 1 }}
                onChange={newValue => {
                  const startTimestamp = combineDateWithTime(selectedDateRange[0], newValue);
                  setSelectedDateRange([startTimestamp, selectedDateRange[1]]);
                  setTimeFrom(newValue);
                }}
              />
              <TimePicker
                label="End Time"
                ampm={false}
                value={timeTo}
                timeSteps={{ minutes: 1 }}
                onChange={newValue => {
                  const endTimestamp = combineDateWithTime(selectedDateRange[1], newValue);
                  setSelectedDateRange([selectedDateRange[0], endTimestamp]);
                  setTimeTo(newValue);
                }}
              />
            </Stack>
          </Stack>
        )}
        {isDesktop && (
          <Stack direction="column">
            <span
              style={{
                color: '#747474',
                fontSize: '12px',
                fontWeight: '400',
              }}
            >
              Select Date Range
            </span>
            <Stack direction="row" display="flex" alignItems="center">
              <span
                style={{
                  fontSize: '24px',
                  fontWeight: '500',
                  marginRight: '5px',
                }}
              >
                {selectedDateRange[0] !== null
                  ? selectedDateRange[0].format('MMM D')
                  : 'Start Date'}
              </span>{' '}
              -
              <span
                style={{
                  fontSize: '24px',
                  fontWeight: '500',
                  marginLeft: '5px',
                }}
              >
                {selectedDateRange[1] !== null ? selectedDateRange[1].format('MMM D') : 'End Date'}
              </span>
            </Stack>
          </Stack>
        )}
        {isMobile && (
          <Stack direction="column" pl={3}>
            <span
              style={{
                color: '#747474',
                fontSize: '12px',
                fontWeight: '400',
              }}
            >
              Select Date Range
            </span>
            <Stack direction="row" display="flex" alignItems="center">
              <span
                style={{
                  fontSize: '24px',
                  fontWeight: '500',
                  marginRight: '5px',
                }}
              >
                {selectedDateRange[0] !== null
                  ? selectedDateRange[0].format('MMM D')
                  : 'Start Date'}
              </span>{' '}
              -
              <span
                style={{
                  fontSize: '24px',
                  fontWeight: '500',
                  marginLeft: '5px',
                }}
              >
                {selectedDateRange[1] !== null ? selectedDateRange[1].format('MMM D') : 'End Date'}
              </span>
            </Stack>
          </Stack>
        )}
        <Stack
          direction={isTablet ? 'row' : 'column'}
          gap={3}
          sx={{
            maxWidth: '760px',
            width: '100%',
          }}
        >
          {!isTablet && (
            <Stack gap={3}>
              <TimePicker
                label="Start Time"
                ampm={false}
                value={timeFrom}
                timeSteps={{ minutes: 1 }}
                onChange={newValue => {
                  const startTimestamp = combineDateWithTime(selectedDateRange[0], newValue);
                  setSelectedDateRange([startTimestamp, selectedDateRange[1]]);
                  setTimeFrom(newValue);
                }}
              />
              <TimePicker
                label="End Time"
                ampm={false}
                value={timeTo}
                timeSteps={{ minutes: 1 }}
                onChange={newValue => {
                  const endTimestamp = combineDateWithTime(selectedDateRange[1], newValue);
                  setSelectedDateRange([selectedDateRange[0], endTimestamp]);
                  setTimeTo(newValue);
                }}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
      {isDesktop && <Divider orientation="vertical" />}
    </Stack>
  );
};

export default function SettingsDialog({
  parent,
  onClose,
  widget,
  widgetDateAndTime,
  setIsBottomBarOpen,
  setChangedWidgets,
  changedWidgets,
  showClock,
  setShowClock,
  setWidgetTimeFrame,
  timeFrame,
  setCustomTimeRangeSettings,
  dashboardId,
  setParent,
  setTimeRangeVal,
}: any) {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const isDesktop = useIsDesktop();
  //Widget level minimum settings (not applicable for dashboard!)

  //Dashboard & Widget level date & time settings
  const today = dayjs();
  const startOfToday = today.startOf('day');
  const endOfToday = today.endOf('day');
  const [selectedDateRange, setSelectedDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    startOfToday,
    endOfToday,
  ]);
  const [timeRangeType, setTimeRangeType] = useState('absolute');
  const [timeFrom, setTimeFrom] = useState<any>();
  const [timeTo, setTimeTo] = useState<any>();

  //Dashboard granularity settings
  const [unit, setUnit] = useState('days');
  const [unitMultiplier, setUnitMultiplier] = useState(1);

  const setDateTimeRange = (startTime: any, endTime: any) => {
    const start = dayjs(startTime);
    const end = dayjs(endTime);
    setSelectedDateRange([start, end]);
    setTimeFrom(start);
    setTimeTo(end);
  };

  useEffect(() => {
    if (parent !== 'widget') {
      setDateTimeRange(timeFrame.startDate, timeFrame.endDate);
    } else {
      //Widget minimum settings

      const existingWidgetIndex = changedWidgets.findIndex(
        (widget: any) => widget.widgetId === widgetDateAndTime.widgetId
      );
      if (existingWidgetIndex !== -1) {
        let selectedTimeRangeForWidget = changedWidgets[existingWidgetIndex]?.['selectedTimeRange'];

        let widgetTimeFrame =
          selectedTimeRangeForWidget && showClock == 'on'
            ? calculateTimeFrame(changedWidgets[existingWidgetIndex]['selectedTimeRange'])
            : timeFrame;
        setDateTimeRange(widgetTimeFrame.startDate, widgetTimeFrame.endDate);
      } else {
        //Apply widget custom settings if it is said so!
        if (widgetDateAndTime && widgetDateAndTime.advancedWidgetSettings?.applyCustomSettings) {
          let widgetTimeFrame =
            calculateTimeFrame(widgetDateAndTime.customTimeRangeSettings) || timeFrame;
          setDateTimeRange(widgetTimeFrame.startDate, widgetTimeFrame.endDate);
        } else {
          setDateTimeRange(timeFrame.startDate, timeFrame.endDate);
        }
      }
    }
  }, [widgetDateAndTime]);

  const handleDateRangeChange = (value: any, context: any) => {
    setSelectedDateRange(value);
    setTimeFrom(value[0]);
    setTimeTo(value[1]);
    const timeRangeMappings: any = {
      Today: { timeRangeType: 'relative', unitMultiplier: 0, unit: 'days' },
      'Last 24 Hours': {
        timeRangeType: 'relative',
        unitMultiplier: 1,
        unit: 'days',
      },
      Yesterday: {
        timeRangeType: 'relative',
        unitMultiplier: -1,
        unit: 'days',
      },
      'Last 3 Days': {
        timeRangeType: 'relative',
        unitMultiplier: 3,
        unit: 'days',
      },
      'Last 7 Days': {
        timeRangeType: 'relative',
        unitMultiplier: 7,
        unit: 'days',
      },
      'Last 10 Days': {
        timeRangeType: 'relative',
        unitMultiplier: 10,
        unit: 'days',
      },
      'Last 30 Days': {
        timeRangeType: 'relative',
        unitMultiplier: 30,
        unit: 'days',
      },
      'Last 3 Months': {
        timeRangeType: 'relative',
        unitMultiplier: 3,
        unit: 'months',
      },
      'Last 6 Months': {
        timeRangeType: 'relative',
        unitMultiplier: 6,
        unit: 'months',
      },
    };

    const mapping = timeRangeMappings[context?.shortcut?.label];
    if (mapping) {
      setTimeRangeType(mapping.timeRangeType);
      setUnitMultiplier(mapping.unitMultiplier);
      setUnit(mapping.unit);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleApplyClick = () => {
    if (!selectedDateRange[0] || !selectedDateRange[1]) return;
    setIsBottomBarOpen(true);
    const startTimestamp = combineDateWithTime(selectedDateRange[0], timeFrom);
    const endTimestamp = combineDateWithTime(selectedDateRange[1], timeTo);
    handleWidgetUpdate(startTimestamp, endTimestamp);
    onClose();
  };

  const calculateTimeRangeInMins = (startTimestamp: any, endTimestamp: any) => {
    return (endTimestamp.$d.valueOf() - startTimestamp.$d.valueOf()) / (1000 * 60);
  };

  const combineDateWithTime = (date: any, time: any) => {
    return dayjs(date)
      .set('hour', dayjs(time).hour())
      .set('minute', dayjs(time).minute())
      .set('second', dayjs(time).second())
      .set('millisecond', dayjs(time).millisecond());
  };

  const handleWidgetUpdate = (startTimestamp: any, endTimestamp: any) => {
    const updatedWidget = {
      dashboardId: dashboardId,
      widgetId: widgetDateAndTime?.widgetId || '',
      selectedTimeRange: {
        unit,
        unitMultiplier,
        timeRangeType,
        startTime: startTimestamp.$d,
        endTime: endTimestamp.$d,
      },
      selectedChart: {
        widgetType: WIDGET_TYPE['flocDetector'],
        graphType: GRAPH_TYPE['flocDetector'],
      },
      applyCustomSettings: showClock == 'on' ? true : false,
      timeRange: timeRangeMarks,
    };

    const widgetTimeFrame = {
      startDate: startTimestamp.$d,
      endDate: endTimestamp.$d,
      timeRangeType,
    };

    setShowClock('on');
    setParent('widget');
    setCustomTimeRangeSettings({
      unit,
      unitMultiplier,
      timeRangeInMins: calculateTimeRangeInMins(startTimestamp, endTimestamp),
      timeRangeType,
      startTime: startTimestamp.$d,
      endTime: endTimestamp.$d,
    });

    setWidgetTimeFrame(widgetTimeFrame);
    updateChangedWidgets(updatedWidget);
  };

  const updateChangedWidgets = (updatedWidget: any) => {
    const tempChangedWidgets = [...changedWidgets];
    const existingWidgetIndex = tempChangedWidgets.findIndex(
      widget => widget.widgetId === updatedWidget.widgetId
    );

    if (existingWidgetIndex !== -1) {
      tempChangedWidgets[existingWidgetIndex] = updatedWidget;
    } else {
      tempChangedWidgets.push(updatedWidget);
    }

    setChangedWidgets(tempChangedWidgets);
  };

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [timeRangeMarks, setTimeRangeMarks] = useState<string[]>(
    widget.timeRange || ['30Mark', '60Mark', '90Mark']
  );

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    setTimeRangeVal(timeRangeMarks);
  }, [timeRangeMarks]);

  return (
    <DateRangeContext.Provider
      value={{
        selectedDateRange,
        setSelectedDateRange,
        timeFrom,
        setTimeFrom,
        timeTo,
        setTimeTo,
        combineDateWithTime,
      }}
    >
      <ThemeProvider theme={dpTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDateRangePicker
            slots={{ toolbar: LeftToolbar }}
            slotProps={{
              shortcuts: {
                items: shortcutsItems,
              },
              actionBar: { actions: [] },
            }}
            calendars={isTablet || isDesktop ? 2 : 1}
            onChange={handleDateRangeChange}
            value={selectedDateRange} // Pass selectedDateRange to reflect it on the calendar
            disableFuture={true}
            //@ts-ignore
            sx={DateAndTimeStyles.DateRangeStyle}
          />
          <Stack gap={isMobile ? 0 : 4}>
            <Stack
              display="flex"
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              gap={4}
            >
              <Stack
                direction={isMobile ? 'column' : 'row'}
                alignItems="center"
                justifyContent={isDesktop ? 'flex-end' : 'flex-start'}
                sx={{
                  '.MuiStack-root': {
                    alignSelf: isMobile ? 'flex-start' : 'center',
                  },
                }}
                width="100%"
                gap={isTablet ? 4 : 0}
              >
                {isDesktop && (
                  <Stack direction="row" alignSelf="flex-end" spacing={3}>
                    <PrimaryButton
                      type="submit"
                      sx={DateAndTimeStyles.NegativeButtonStyle}
                      onClick={handleCancel}
                    >
                      <Typography variant="h6">CANCEL</Typography>
                    </PrimaryButton>
                    <PrimaryButton
                      type="submit"
                      sx={DateAndTimeStyles.PositiveButtonStyle}
                      onClick={handleApplyClick}
                      disabled={!selectedDateRange[0] || !selectedDateRange[1]}
                    >
                      <Typography variant="h6">APPLY</Typography>
                    </PrimaryButton>
                  </Stack>
                )}
              </Stack>
            </Stack>
            {isTablet && (
              <Stack direction="row" gap={4}>
                <PrimaryButton
                  type="submit"
                  sx={DateAndTimeStyles.NegativeButtonStyle}
                  onClick={handleCancel}
                >
                  <Typography variant="h6">CANCEL</Typography>
                </PrimaryButton>
                <PrimaryButton
                  type="submit"
                  sx={DateAndTimeStyles.PositiveButtonStyle}
                  onClick={handleApplyClick}
                  disabled={!selectedDateRange[0] || !selectedDateRange[1]}
                >
                  <Typography variant="h6">APPLY</Typography>
                </PrimaryButton>
              </Stack>
            )}
            {!isDesktop && !isTablet && (
              <Stack direction="column" gap={2}>
                <PrimaryButton
                  type="submit"
                  sx={DateAndTimeStyles.NegativeButtonStyle}
                  onClick={handleCancel}
                >
                  <Typography variant="h6">CANCEL</Typography>
                </PrimaryButton>
                <PrimaryButton
                  type="submit"
                  sx={DateAndTimeStyles.PositiveButtonStyle}
                  onClick={handleApplyClick}
                  disabled={!selectedDateRange[0] || !selectedDateRange[1]}
                >
                  <Typography variant="h6">APPLY</Typography>
                </PrimaryButton>
              </Stack>
            )}
          </Stack>
        </LocalizationProvider>
        <StaticDateTimePickerComponent
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
        />
      </ThemeProvider>
    </DateRangeContext.Provider>
  );
}
