import { Box, Dialog, Divider, Stack, Tooltip, Typography } from '@mui/material';
import styles from './styles/VisualizationChartStyling';
import LineChartFlocDetector from './FlocDetectorCharts/LineChartFlocDetector';
import FactoryIcon from '@mui/icons-material/Factory';
import linkIcon from '../../constants/Images/linkIcon.svg';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StackedBarChartFlocDetector from './FlocDetectorCharts/StackBarChartFlocDetector';
import IOSZoomSwitch from '../../core-components/theme/components/Button/IOSZoomSwitch';
import { useState } from 'react';

const ExpandedViewFlocDetectorWidget = (props: any) => {
  const {
    selectedDate,
    selectedSampleIndex,
    handleClickFlocOpen,
    openExpandedView,
    handleCloseExpandedView,
    widgetData,
    showLinkIcon,
    linkOnWidget,
    barGraphData,
    selectedTypeGraph,
    imageCache,
    flocData,
    issueIcon,
    hiddenLabels,
    setOpenExpandedView,
  } = props;
  const clickedOnlinkIcon = () => {
    window.location.href = linkOnWidget;
  };

  const [expandedVersion, setExpandedVersion] = useState<any>(false);
  return (
    <>
      <Dialog
        open={openExpandedView}
        onClose={() => handleCloseExpandedView(setOpenExpandedView)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '.MuiDialog-paper': {
            maxWidth: 'none',
            // padding: "0.5rem",
            borderRadius: '16px',
            height: selectedTypeGraph === 'Sludge Settling Pattern' ? '60vh' : 'auto',
            width: selectedTypeGraph === 'Sludge Settling Pattern' ? '180vh' : 'auto',
            overflow: 'hidden', // Prevent scrollbars
            display: 'flex', // Use flexbox to align content
            flexDirection: 'column', // Stack children vertically
          },

          '.MuiDialog-paper > *': {
            flex: '1 1 auto', // Allow children to grow and shrink to fit
            overflow: 'hidden', // Ensure child content does not overflow
            minHeight: '0', // Allow child elements to collapse correctly
          },
        }}
      >
        <Box className="widgetHeader" sx={{ ...styles.widgetHeader }}>
          <Stack direction="row" alignItems="center">
            <Tooltip title={widgetData?.widgetNickName || widgetData?.widgetName}>
              <Typography variant="h6" sx={styles.textStyle}>
                {widgetData?.widgetNickName || widgetData?.widgetName}
              </Typography>
            </Tooltip>
            {widgetData?.plantName && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    marginLeft: '8px',
                    marginRight: '8px',
                    borderRightWidth: 2,
                    color: '#7D8188',
                  }}
                />
                <Stack direction="row" alignItems="center" gap={1}>
                  <FactoryIcon
                    fontSize="small"
                    sx={{
                      width: '16px',
                      height: '16px',
                      cursor: 'pointer',
                      color: '#80858CD9',
                      alignSelf: 'center',
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: '#7D8188', fontSize: '14px', fontWeight: 600 }}
                  >
                    {widgetData?.plantName}
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            {
              <IOSZoomSwitch
                checked={expandedVersion}
                onChange={() => setExpandedVersion(!expandedVersion)} // Toggle zoom on/off
              />
            }
            {showLinkIcon && (
              <Tooltip title="Click to know more" style={{ padding: '0' }}>
                <img
                  src={linkIcon}
                  style={{ width: '14px', height: '14px' }}
                  onClick={() => {
                    clickedOnlinkIcon();
                  }}
                ></img>
              </Tooltip>
            )}
            <Tooltip title={widgetData?.widgetDescription}>
              <InfoOutlinedIcon fontSize="small" style={{ color: '#98A6B8' }} />
            </Tooltip>
            <Tooltip title="Close window">
              <CloseFullscreenIcon
                sx={{ fontSize: '16px', cursor: 'pointer' }}
                onClick={() => handleCloseExpandedView(setOpenExpandedView)}
              />
            </Tooltip>
          </Stack>
        </Box>
        {selectedTypeGraph === 'Sludge Value' && barGraphData && barGraphData.length > 0 && (
          <StackedBarChartFlocDetector
            handleClickFlocOpen={handleClickFlocOpen}
            issueIcon={issueIcon}
            hiddenLabels={hiddenLabels}
            barGraphData={barGraphData}
            barGraphStandard={true}
            expandedVersion={expandedVersion}
          />
        )}
        {selectedTypeGraph === 'Sludge Settling Pattern' && flocData && flocData.length > 0 && (
          <LineChartFlocDetector
            flocData={flocData}
            selectedDate={selectedDate}
            selectedSampleIndex={selectedSampleIndex}
            imageCache={imageCache}
            handleClickFlocOpen={handleClickFlocOpen}
            expandedVersion={expandedVersion}
          />
        )}
      </Dialog>
    </>
  );
};

export default ExpandedViewFlocDetectorWidget;
