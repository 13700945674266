import { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { fetchFlocImgData } from '../../../services/Flocdetector/FlocVisualizationChartHelper';

const LineChartFlocDetector = ({
  imageCache,
  flocData,
  selectedDate,
  selectedSampleIndex,
  handleClickFlocOpen,
  setImgId,
  setOpen,
  setImageDetails,
  setAllComments,
  expandedVersion,
}: any) => {
  // Extract data for the selected date and sample
  const selectedSample = flocData[selectedDate]?.samples[selectedSampleIndex] || {};
  const selectedSampleData = selectedSample?.data || {};
  const startTime = selectedSample?.startTime ? new Date(selectedSample.startTime).getTime() : 0;
  const graphData: any = Object.values(selectedSampleData);
  const minuteValues = Object.keys(selectedSampleData).map(key => {
    const detectedTime = selectedSampleData[key]?.detectedTime
      ? new Date(selectedSampleData[key].detectedTime).getTime()
      : 0;
    const diffInMinutes = Math.floor((detectedTime - startTime) / 60000); // Convert milliseconds to minutes
    return diffInMinutes;
  });

  const preloadImages = (urls: string[]) => {
    urls.forEach(url => {
      if (!imageCache[url]) {
        const img = new Image();
        img.src = url;
        imageCache[url] = img;
      }
    });
  };

  const data = {
    labels: minuteValues,
    datasets: [
      {
        label: 'thumbnail',
        data: graphData.map((item: { value: number }) => item.value),
        borderColor: '#CA6B02',
        backgroundColor: 'rgba(202, 107, 2, 0.2)',
        tension: 0.4,
        fill: 'start',
        pointRadius: 2,
        pointBackgroundColor: '#111',
        pointBorderColor: '#FFFFFF',
        pointBorderWidth: 1.5,
        pointHoverRadius: 5,
      },
    ],
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const xValue = minuteValues[context.parsed.x];
            const yValue = context.parsed.y;
            return `${xValue} minutes, ${yValue}`;
          },
        },
      },
      zoom: expandedVersion
        ? {
            pan: {
              enabled: true,
              mode: 'xy',
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'xy',
            },
            limits: {
              x: { min: 0, max: 100 },
              y: { min: 0, max: 100 },
            },
          }
        : false,
    },
    scales: {
      x: {
        min: Math.min(...minuteValues),
        max: Math.max(...minuteValues),
        ticks: {
          stepSize:
            minuteValues.length > 5
              ? Math.floor((Math.max(...minuteValues) - Math.min(...minuteValues)) / 5)
              : 1,
          callback: (value: any) => {
            return `${minuteValues[value]} minutes`;
          },
          maxTicksLimit: 10,
        },
      },
      y: {
        min: Math.min(...graphData.map((item: any) => item.value)),
        max: Math.max(...graphData.map((item: any) => item.value)),
        ticks: {
          stepSize: 10,
          callback: (value: any) => {
            return `${value} %`;
          },
        },
      },
    },
  };

  const drawImagesPlugin = {
    id: 'drawImagesPlugin',
    beforeDatasetsDraw: (chart: any) => {
      const ctx = chart.ctx;
      const canvas = chart.canvas;

      const images: any[] = [];

      canvas.onclick = (evt: any) => {
        const res = images.map(({ img, x, y, imageId, thumbnail }) => {
          const width = thumbnail ? 30 : 12;
          const height = 30;
          const { offsetX, offsetY } = evt;
          if (
            offsetX >= x - width / 2 &&
            offsetX <= x + width / 2 &&
            offsetY >= y - height - 10 &&
            offsetY <= y - 10
          ) {
            return imageId;
          }
          return null;
        });
        for (let id of res) {
          if (id !== null) {
            handleClickFlocOpen(
              id,
              setImgId,
              setOpen,
              fetchFlocImgData(id, setImageDetails, setAllComments)
            );
          }
        }
      };

      canvas.onmousemove = (evt: any) => {
        let hovering = false;
        images.forEach(({ x, y, thumbnail }) => {
          const width = thumbnail ? 30 : 12;
          const height = 30;
          const { offsetX, offsetY } = evt;
          if (
            offsetX >= x - width / 2 &&
            offsetX <= x + width / 2 &&
            offsetY >= y - height - 10 &&
            offsetY <= y - 10
          ) {
            hovering = true;
          }
        });
        canvas.style.cursor = hovering ? 'pointer' : 'default';
      };

      chart.data.labels.forEach((label: any, dataIndex: any) => {
        const datasets = chart.data.datasets;
        datasets.forEach((dataset: any, datasetIndex: number) => {
          const value = dataset?.data[dataIndex];
          const imgUrl = graphData?.[dataIndex]?.[dataset.label] ?? null;
          const imageId = graphData?.[dataIndex]?.imageId ?? null;
          if (imgUrl && value && !dataset.hidden) {
            const point = chart.getDatasetMeta(datasetIndex).data[dataIndex].getProps(['x', 'y']);
            images.push({
              img: imageCache[imgUrl],
              x: point.x,
              y: point.y,
              imageId,
              thumbnail: dataset.label === 'thumbnail',
            });
          }
        });
      });

      images.forEach(({ img, x, y, thumbnail }) => {
        const width = thumbnail ? 30 : 12;
        const height = 30;
        ctx.drawImage(img, x - width / 2, y - height - 10, width, height);
      });
    },
  };

  useEffect(() => {
    preloadImages(graphData.map((item: any) => item.thumbnail));
  }, [graphData]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: 'white', // Set the background to white
        border: 'none', // Remove any border
      }}
    >
      <Line data={data} options={options} plugins={[drawImagesPlugin]} />
    </div>
  );
};

export default LineChartFlocDetector;
