import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import DesktopViewVisualizationHeader from './SubComponents/DesktopViewVisualizationHeader';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useCallback, useEffect, useRef, useState } from 'react';
// import convertISOToLocalTime from '../../globalComponents/dateAndTime/ConvertISOIntoLocalTime';
import useIsTablet from '../../utilities/customHooks/useIsTablet';
import LineChartFlocDetector from './FlocDetectorCharts/LineChartFlocDetector';
import StackedBarChartFlocDetector from './FlocDetectorCharts/StackBarChartFlocDetector';
import {
  downloadCSV,
  downloadCSVForBarGraph,
  formatTimeRange,
  getDayMonthYear,
  handleOnClickIssueIcon,
  handleOnClickMinutesIcon,
  processData,
} from './Helper';
import ImageDialogueBox from './FlocDetectorCommentDialogueBox/ImageDialogueBox';
import CommentDialogueBox from './FlocDetectorCommentDialogueBox/CommentDialogueBox';
import useIsDesktop from '../../utilities/customHooks/useIsDesktop';
import ExpandedViewFlocDetectorWidget from './ExpandedVisualizationChart';
import {
  handleSelectedGraphType,
  formatTime,
  handleAddComment,
  handleClick,
  handleClickFlocOpen,
  handleClickOpenExpandedView,
  handleFlocClose,
  handleCloseComment,
  handleCloseCommentDialogue,
  handleCloseCommentDialogueOutside,
  handleCloseExpandedView,
  handleOnChangeComment,
} from '../../services/Flocdetector/FlocVisualizationChartHelper';
import MobileViewVisualizationHeader from './SubComponents/MobileViewVisualizationHeader';
import Theme from '../../core-components/theme/theme';
ChartJS.unregister(zoomPlugin);
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip);
const FlocDetectorVisualizationChart = (props: any) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const {
    widgetData,
    showLinkIcon,
    linkOnWidget,
    handleEditClick,
    showClock,
    customTimeRangeSettings,
    timeFrame,
    handleClockClick,
    flocData,
    widgetTimeFrame,
    setSelectedChart,
    selectChart,
  } = props;
  const isMobile = useMediaQuery(Theme.breakpoints.down('extraSm'));
  const isTablet = useMediaQuery(Theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(Theme.breakpoints.down('lg'));
  const [selectedTypeGraph, setSelectedTypeGraph] = useState<any>('Sludge Settling Pattern');

  const barGraphData = widgetData?.metricsData?.barGraph;

  const [addComment, setAddComment] = useState({
    title: '',
    comment: '',
  });

  const [allComments, setAllComments] = useState<any>([]);
  const [imageDetails, setImageDetails] = useState<any>(null);
  const [imgId, setImgId] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentSampleIndex, setCurrentSampleIndex] = useState(0);
  let currentStartDate: React.MutableRefObject<string> = useRef(timeFrame.startDate.toISOString());
  let currentEndDate: React.MutableRefObject<string> = useRef(timeFrame.endDate.toISOString());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openComment = Boolean(anchorEl);
  const handleFlocCloseCallback = useCallback(() => {
    handleFlocClose(setOpen);
  }, [handleFlocClose]);
  const [anchorElComment, setAnchorElComment] = useState<null | HTMLElement>(null);
  const openCommentDialogue = Boolean(anchorElComment);
  const [commentDialogueHeaderText, setCommentDialogueHeaderText] = useState(null);
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorComment, setErrorComment] = useState(false);

  const imageCache: { [url: string]: HTMLImageElement } = {};
  const [issueIcon, setIssueIcon] = useState<{ [key: string]: boolean }>({});
  const [hiddenLabels, setHiddenLabels] = useState<{ [key: string]: boolean }>({});
  const [currentDateIndex, setCurrentDateIndex] = useState(0); // Tracks the current date

  const currentData = flocData ? flocData[currentDateIndex] : {};
  const sampleKeys = Object.keys(currentData?.samples || {});
  const currentSampleKey = sampleKeys[currentSampleIndex];
  const currentSample = currentData?.samples ? currentData?.samples[currentSampleKey] : {};

  const [visibleIcons, setVisibleIcons] = useState<any>({
    issueIcon: false,
    falseDetection: false,
    noSludge: false,
  });

  // THIS USEEFFECT IS FOR THE CLOCK THING AND IS FINE I GUESS
  useEffect(() => {
    currentStartDate.current =
      showClock === 'on'
        ? customTimeRangeSettings?.startTime instanceof Date
          ? customTimeRangeSettings.startTime.toISOString()
          : new Date(customTimeRangeSettings?.startTime).toISOString()
        : new Date(timeFrame?.startDate).toISOString();

    currentEndDate.current =
      showClock === 'on'
        ? customTimeRangeSettings?.endTime instanceof Date
          ? customTimeRangeSettings.endTime.toISOString()
          : new Date(customTimeRangeSettings?.endTime).toISOString()
        : new Date(timeFrame?.endDate).toISOString();
  }, [
    timeFrame?.startDate,
    timeFrame?.endDate,
    customTimeRangeSettings?.startTime,
    customTimeRangeSettings?.endTime,
  ]);

  const [openExpandedView, setOpenExpandedView] = useState(false);
  const [resetZoom, setResetZoom] = useState(false);

  useEffect(() => {
    if (setSelectedChart && selectChart) {
      setSelectedChart('flocDetector');
    }
  }, [setSelectedChart, selectChart]);

  return (
    <>
      <Grid
        container
        md={12}
        lg={12}
        border="1px solid #0000003B"
        borderRadius="16px"
        pl="12px"
        pt="10px"
        pr="20px"
        pb="0px"
        display="flex"
        flexDirection="column"
        width="100%"
        height={'100%'}
        sx={{ backgroundColor: '#ffffff', flexWrap: 'nowrap' }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div>
          {!isMobile ? (
            <DesktopViewVisualizationHeader
              widgetData={widgetData}
              isHovered={isHovered}
              isDesktop={isDesktop}
              showClock={showClock}
              handleClockClick={handleClockClick}
              formatTimeRange={formatTimeRange}
              widgetTimeFrame={widgetTimeFrame}
              handleSelectedGraphType={handleSelectedGraphType}
              barGraphData={barGraphData}
              selectedTypeGraph={selectedTypeGraph}
              flocData={flocData}
              currentDateIndex={currentDateIndex}
              currentSampleIndex={currentSampleIndex}
              isMobile={isMobile}
              currentData={currentData}
              currentSampleKey={currentSampleKey}
              formatTime={formatTime}
              sampleKeys={sampleKeys}
              currentSample={currentSample}
              handleOnClickIssueIcon={handleOnClickIssueIcon}
              issueIcon={issueIcon}
              setIssueIcon={setIssueIcon}
              setVisibleIcons={setVisibleIcons}
              downloadCSV={downloadCSV}
              getDayMonthYear={getDayMonthYear}
              processData={processData}
              downloadCSVForBarGraph={downloadCSVForBarGraph}
              handleClickOpenExpandedView={handleClickOpenExpandedView}
              handleEditClick={handleEditClick}
              isTablet={isTablet}
              visibleIcons={visibleIcons}
              handleOnClickMinutesIcon={handleOnClickMinutesIcon}
              setHiddenLabels={setHiddenLabels}
              hiddenLabels={hiddenLabels}
              setCurrentSampleIndex={setCurrentSampleIndex}
              setCurrentDateIndex={setCurrentDateIndex}
              setSelectedTypeGraph={setSelectedTypeGraph}
              setOpenExpandedView={setOpenExpandedView}
            />
          ) : (
            <MobileViewVisualizationHeader
              widgetData={widgetData}
              isHovered={isHovered}
              isDesktop={isDesktop}
              showClock={showClock}
              handleClockClick={handleClockClick}
              formatTimeRange={formatTimeRange}
              widgetTimeFrame={widgetTimeFrame}
              handleSelectedGraphType={handleSelectedGraphType}
              barGraphData={barGraphData}
              selectedTypeGraph={selectedTypeGraph}
              flocData={flocData}
              currentDateIndex={currentDateIndex}
              currentSampleIndex={currentSampleIndex}
              isMobile={isMobile}
              currentData={currentData}
              currentSampleKey={currentSampleKey}
              formatTime={formatTime}
              sampleKeys={sampleKeys}
              currentSample={currentSample}
              handleOnClickIssueIcon={handleOnClickIssueIcon}
              issueIcon={issueIcon}
              setIssueIcon={setIssueIcon}
              setVisibleIcons={setVisibleIcons}
              downloadCSV={downloadCSV}
              getDayMonthYear={getDayMonthYear}
              processData={processData}
              downloadCSVForBarGraph={downloadCSVForBarGraph}
              handleClickOpenExpandedView={handleClickOpenExpandedView}
              handleEditClick={handleEditClick}
              isTablet={isTablet}
              visibleIcons={visibleIcons}
              handleOnClickMinutesIcon={handleOnClickMinutesIcon}
              setHiddenLabels={setHiddenLabels}
              hiddenLabels={hiddenLabels}
              setCurrentSampleIndex={setCurrentSampleIndex}
              setCurrentDateIndex={setCurrentDateIndex}
              setSelectedTypeGraph={setSelectedTypeGraph}
              setOpenExpandedView={setOpenExpandedView}
            />
          )}
        </div>

        <Grid
          container
          mt="0.5rem"
          sx={{
            textAlign: 'center',
            border: '10px',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          {(flocData == 'undefined' || flocData == null || flocData?.length == 0) && (
            <Box
              sx={{
                width: 'inherit',
                height: isMobile ? '70%' : '90%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px dashed #ccc',
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
                padding: '16px',
                marginBottom: '20px',
              }}
            >
              <Typography variant="body1" sx={{ textAlign: 'center', color: 'gray' }}>
                No data available for floc detector for this Date
              </Typography>
            </Box>
          )}

          {/* WILL DEICDE TO KEEP THIS OR NOT  */}
          {/* LOOKS FINE FOR NOW  */}
          {/* THIS IS THE BLOCK WHICH I NEED NOT TO BE TOUCHED STARTING */}
          {selectedTypeGraph === 'Sludge Settling Pattern' && flocData && flocData.length > 0 && (
            // NEED TO CHANGE THE SHIFT LOGIC TO ONLY SAMPLE OVER HERE
            // WILL NEED TO PASS THE COLOR TOO OVER HERE FOR WIDGET THEME
            <Box
              sx={{
                width: 'inherit',
                height: isMobile ? '100%' : '90%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '8px',
                paddingBottom: '10px',
              }}
            >
              <LineChartFlocDetector
                flocData={flocData}
                selectedDate={currentDateIndex}
                selectedSampleIndex={currentSampleKey}
                imageCache={imageCache}
                handleClickFlocOpen={handleClickFlocOpen}
                setImgId={setImgId}
                setOpen={setOpen}
                setImageDetails={setImageDetails}
                setAllComments={setAllComments}
                expandedVersion={false}
              />
            </Box>
          )}
          {selectedTypeGraph === 'Sludge Value' && barGraphData && barGraphData.length > 0 && (
            <StackedBarChartFlocDetector
              handleClickFlocOpen={handleClickFlocOpen}
              issueIcon={issueIcon}
              hiddenLabels={hiddenLabels}
              barGraphData={barGraphData}
              expandedVersion={false}
              setImgId={setImgId}
              setOpen={setOpen}
              setImageDetails={setImageDetails}
              setAllComments={setAllComments}
            />
          )}
          {/* THIS IS THE BLOCK WHICH I NEED NOT TO BE TOUCHED ENDING EXCEPT SHIFT CONCEPT */}
        </Grid>
      </Grid>

      <ImageDialogueBox
        open={open}
        handleFlocClose={handleFlocCloseCallback}
        imageDetails={imageDetails}
        handleClick={handleClick}
        anchorEl={anchorEl}
        openComment={openComment}
        handleCloseComment={handleCloseComment}
        allComments={allComments}
        setAnchorEl={setAnchorEl}
        setOpen={setOpen}
        setAnchorElComment={setAnchorElComment}
        setCommentDialogueHeaderText={setCommentDialogueHeaderText}
      />
      <CommentDialogueBox
        openCommentDialogue={openCommentDialogue}
        handleCloseCommentDialogueOutside={handleCloseCommentDialogueOutside}
        commentDialogueHeaderText={commentDialogueHeaderText}
        handleCloseCommentDialogue={handleCloseCommentDialogue}
        handleOnChangeComment={handleOnChangeComment}
        addComment={addComment}
        errorTitle={errorTitle}
        setErrorTitle={setErrorTitle}
        errorComment={errorComment}
        setErrorComment={setErrorComment}
        handleAddComment={handleAddComment}
        setAnchorElComment={setAnchorElComment}
        setOpen={setOpen}
        setAddComment={setAddComment}
        allComments={allComments}
        setAllComments={setAllComments}
        flocData={flocData}
        currentSampleIndex={currentSampleIndex}
        imgId={imgId}
      />

      <ExpandedViewFlocDetectorWidget
        openExpandedView={openExpandedView}
        handleCloseExpandedView={handleCloseExpandedView}
        widgetData={widgetData}
        resetZoom={resetZoom}
        setResetZoom={setResetZoom}
        showLinkIcon={showLinkIcon}
        linkOnWidget={linkOnWidget}
        selectedTypeGraph={selectedTypeGraph}
        imageCache={imageCache}
        flocData={flocData}
        selectedDate={currentDateIndex}
        selectedSampleIndex={currentSampleKey}
        barGraphData={barGraphData}
        handleClickFlocOpen={handleClickFlocOpen}
        issueIcon={issueIcon}
        hiddenLabels={hiddenLabels}
        setOpenExpandedView={setOpenExpandedView}
      />
    </>
  );
};

export default FlocDetectorVisualizationChart;
