import { MouseEventHandler } from 'react';
import Button from '../Button/Button';
import { styled } from '@mui/material/styles';
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  IconButton as MuiIconButton,
  Typography,
  DialogActions as MuiDialogActions,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const DialogBoxStyle = styled(MuiDialog)(({ theme, dialogStyles={}, ...other }) => {
  return {
  '& .MuiDialog-paper': {
    // padding: '20px',
    margin: 'auto',
    minWidth: '200px',
    maxWidth: '600px',
    borderRadius: '12px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '16px',
      maxWidth: '100%',
      minWidth: 'auto',
    },
    ...dialogStyles
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
  },
  '& .MuiDialogActions-root': {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}});

const DialogTitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2), // Adjusted padding
}));

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  paddingRight: 0, // Adjust padding right for close button alignment
  display: 'flex',
  alignItems: 'center',
}));

export default function DialogBox(props: any) {
  const { title, dialogStyles,  ...otherProps } = props;
  return (
    <>
      <DialogBoxStyle
        maxWidth={props.maxWidth}
        sx={{
          '& .MuiDialog-paper': {
            minWidth: props.minWidth || 'auto',
          },
        }}
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        dialogStyles={{...dialogStyles}}
        {...otherProps}
      >
        <DialogTitleContainer>
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', ...props?.dialogTitleSx }} id="customized-dialog-title">
            <Typography fontSize={props.TitlefontSize} variant="h1">
              {title}
            </Typography>
          </DialogTitle>
          {/* <MuiIconButton aria-label="close" onClick={props.handleClose}>
            <CloseIcon sx={{ color: 'black' }} />
          </MuiIconButton> */}
        </DialogTitleContainer>

        <Divider variant="fullWidth" />

        <MuiDialogContent>{props.children}</MuiDialogContent>
        {props.actions ? (
          <MuiDialogActions sx={{ display: 'flex', justifyContent: 'end' }}>
            {props.actions.map(
              (action: {
                color?: any;
                textColor?: string;
                fullWidth?: boolean;
                text: string;
                disabled?: boolean;
                variant?: any;
                handler: MouseEventHandler<HTMLButtonElement> | undefined;
              }) => (
                <Button
                  color={action?.color}
                  variant={action?.variant}
                  disabled={action?.disabled}
                  fullWidth={action?.fullWidth}
                  onClick={action.handler}
                >
                  {action.text}
                </Button>
              )
            )}
          </MuiDialogActions>
        ) : null}
      </DialogBoxStyle>
    </>
  );
}
