import axiosInstance from '../constants/axiosInstance';

interface WorkspaceListProps {
  workspace: string
}

const getWorkspace = async (workspaceId: string) => {
  try {
    const { data } = await axiosInstance().post(`/workspace/getWorkspace/${workspaceId}`);
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error fetching workspace:', error);
  }
};

const saveWorkspace = async (workspaceData: any) => {
  // Replace 'any' with your workspace data type
  try {
    const { data } = await axiosInstance().post('/workspace/saveWorkspace', workspaceData);
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error saving workspace:', error);
  }
};

const getWorkspaceConfigList = async () => {
  try {
    const { data } = await axiosInstance().post(`/workspace/getWorkspaceConfigList`);
    return data;
  } catch (error) {
    console.error('Error fetching workspace configuration list:', error);
  }
};

const deleteWorkspace = async (workspaceId: string) => {
  try {
    const response = await axiosInstance().post(`/workspace/deleteWorkspace/${workspaceId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting workspace:', error);
    throw error;
  }
};

const getListOfWorkspaceForClient = async () => {
  try {
    const { data } = await axiosInstance().post(`/workspace/getListOfWorkspacesForClient`);
    return data;
  } catch (error) {
    console.error('Error fetching workspace configuration list:', error);
  }
};

const getListOfPlantsForWorkspace = async (workspaceId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/workspace/getListOfPlantsForWorkspace?workspaceId=${workspaceId}`
    );
    return data;
  } catch (error) {
    console.error('Error fetching workspace configuration list:', error);
  }
};

const getWorkspaceListAutoComplete = async (workspaceData: WorkspaceListProps) => {
  try {
    const { data } = await axiosInstance().post('/formula/getWorkspaceListAutocomplete', workspaceData);
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error saving workspace:', error);
  }
};

export {
  getWorkspace,
  saveWorkspace,
  getWorkspaceConfigList,
  deleteWorkspace,
  getListOfWorkspaceForClient,
  getListOfPlantsForWorkspace,
  getWorkspaceListAutoComplete
};
