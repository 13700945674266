import { Box } from '@mui/material';
import { useState } from 'react';
import GeneralisedWidgetConfigHeader from '../../GeneralisedWidgetComponents/subComponents/generalisedWidgetConfigHeader';
import DeleteConfirmation from '../../../globalComponents/dialogs/DeleteConfirmation';
import FlocConfigPopup from './flocConfigPopup';
import StandaloneGeneralisedWidget from '../../GeneralisedWidgetComponents/StandaloneGeneralisedWidget';

const currentTime = new Date();
const midnight = new Date();
midnight.setHours(0, 0, 0, 0);

export default function DndFlocDetectorConfig({
  widget,
  pageData,
  setPageData,
  widgetClusterIndex,
  refresh,
  setRefresh,
  widgetIndex,
  handleWidgetSizeChange,
  changeWidgetTheme,
  deleteCustomWidget,
  widgetCluster,
  countFlocDetectors,
  selectedPlant,
}: any) {
  const [openWidgetConfig, setOpenWidgetConfig] = useState(false);
  const [showConfigHeader, setShowConfigHeader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [refreshStandAloneWidget, setRefreshStandAloneWidget] = useState(false);
  // const [timeFrame, setTimeFrame] = useState({
  //     startDate: midnight,
  //     endDate: currentTime,
  // });
  const [isConfigComplete, setIsConfigComplete] = useState(false);

  const handleConfigComplete = () => {
    setIsConfigComplete(true);
  };

  const onWidgetSizeChange = (width: number, height: number) => {
    handleWidgetSizeChange(widgetClusterIndex, widgetIndex, width, height);
  };

  const onWidgetThemeChange = (theme: string) => {
    changeWidgetTheme(widgetClusterIndex, widgetIndex, theme);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  return (
    <Box
      sx={{ width: 'inherit', height: 'inherit' }}
      onMouseEnter={(event: any) => {
        event.stopPropagation();
        setShowConfigHeader(true);
      }}
      onMouseLeave={(event: any) => {
        event.stopPropagation();
        setShowConfigHeader(false);
      }}
    >
      {showConfigHeader && (
        <Box
          sx={{
            width: 'calc(100% - 1px)',
          }}
        >
          <GeneralisedWidgetConfigHeader
            onConfigButtonPress={() => {
              setOpenWidgetConfig(true);
              setShowConfigHeader(false);
            }}
            onWidgetSizeChange={onWidgetSizeChange}
            widgetType={widget.widgetType}
            onDeleteButtonPress={() => {
              setShowDeleteModal(true);
            }}
            onWidgetThemeChange={onWidgetThemeChange}
            onCloseHeader={() => {
              setShowConfigHeader(false);
            }}
            widgetStructure={widget}
            timeFrame={{
              startDate: midnight.toString(),
              endDate: currentTime.toString(),
            }}
          />
        </Box>
      )}

      {/* <SankeyWidget
                widgetId={null}
                widgetStructure={widget}
                refresh={refreshStandAloneWidget}
                timeFrame={timeFrame}
                enableToolBar={false}
            /> */}

      {/* setMakingSampleDefault,
            widgetData,
            showLinkIcon,
            linkOnWidget,
            handleEditClick,
            showClock,
            customTimeRangeSettings,
            timeFrame,
            handleClockClick,
            widgetId,
            plantId,
            flocData,
            setFlocData,
            widgetTimeFrame, */}

      <FlocConfigPopup
        open={openWidgetConfig}
        onClose={() => setOpenWidgetConfig(false)}
        widget={widget}
        pageData={pageData}
        setPageData={setPageData}
        widgetCluster={widgetCluster}
        widgetClusterIndex={widgetClusterIndex}
        refresh={refresh}
        setRefresh={setRefresh}
        widgetIndex={widgetIndex}
        countFlocDetectors={countFlocDetectors}
        onConfigComplete={handleConfigComplete}
      />

      <StandaloneGeneralisedWidget
        widgetStructure={widget}
        timeFrame={{
          startDate: midnight,
          endDate: currentTime,
          granularity: 'hours',
        }}
        refresh={refreshStandAloneWidget}
        plantId={selectedPlant}
        isConfigComplete={isConfigComplete}
      />

      <DeleteConfirmation
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        alertMessage="Are you sure you want to delete this widget? This action is irreversible!"
        processing={processing}
        setProcessing={setProcessing}
        onConfirmAction={() => {
          deleteCustomWidget(widgetClusterIndex, widgetIndex);
        }}
      />
    </Box>
  );
}
