//@ts-nocheck
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import logo from "../constants/Images/digital-ecoinnovision-logo.png";

import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { MenuItem, Select } from "@mui/material";
import { useNavigate } from "react-router";
import CheckIcon from "@mui/icons-material/Check";
import color from "../styles/color";
import { PrimaryButton } from "../styles/global/components/dpButtons";
import {
  checkinUpdateForROService,
  getPlantNamesForRO,
  onDutyUpdateService,
} from "../services/checkInForRo";
import { checkOutForRO } from "../services/userSelectPopup";
import { getUsers } from "../services/taskList";

export const CheckInPageWrapper = styled.div`
  @media screen and (min-height: 650px) {
    height: 100vh;
  }
  transition-duration: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  padding: 20px 0 40px 0;
`;

const CheckInStyle = {
  logo: {
    width: "40px",
    height: "60px",
    display: "flex",
  },
  color: {
    color: color.dpBlue,
  },
  checkIcon: {
    color: "green",
    marginLeft: "5px",
    marginBottom: "3px",
  },
  actionButtons: {
    height: "35px",
    marginBottom: "15px",
  },
};

export default function CheckInForRO() {
  let history = useNavigate();
  const [plants, setPlants] = useState([]);
  const [checkInPlant, setCheckInPlant] = useState("");
  const [location, setLocation] = useState(null);
  const [deviceId, setDeviceId] = useState("");
  const [onDuty, setOnDuty] = useState(true);
  const [user, setUser] = useState({});
  const [currentlyCheckedInPlantId, setcurrentlyCheckedInPlantId] =
    useState("");

  const checkInUserfnc = async () => {
    localStorage.setItem("checkInPlant", checkInPlant.name);
    checkinUpdateForRO();
    onDutyUpdate();
  };

  const checkinUpdateForRO = async () => {
    checkinUpdateForROService(checkInPlant, true, location, deviceId)
      .then((data) => {
        localStorage.setItem("allowAction", "true");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDutyUpdate = async () => {
    onDutyUpdateService(onDuty)
      .then((data) => {
        localStorage.setItem("allowAction", "true");
        history("/task-list");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkOutUser = async () => {
    checkOutForRO({
      checkIn: {
        id: user._id,
        name: user.name,
        role: user.role,
      },
      checkInStatus: false,
    })
      .then(({ data }) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const continueWithoutCheckInButtonPressed = async () => {
    if (checkInPlant.id === "none") await checkOutUser();

    await onDutyUpdate();

    localStorage.setItem("allowAction", "false");
    history("/task-list");
  };

  useEffect(() => {
    getPlantNamesForRO()
      .then((data) => {
        console.log("data", data);
        //  setName(data.Users);
        setPlants(data);
      })
      .catch((err) => {
        console.log(err);
      });

    getUsers()
      .then((data) => {
        console.log("user : ", data);
        setOnDuty(data.user.onDuty.status);
        setUser(data.user);
        if (data.user.checkedIn)
          setcurrentlyCheckedInPlantId(
            data.user.checkedIn.currentStatus.plantId
          );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getDeviceInfo = async () => {
    await getUserLocation();
    await getDeviceId();
  };

  const getUserLocation = async () => {
    if (navigator.geolocation) {
      const position: any = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      console.log("Latitude: ", position.coords.latitude);
      console.log("Longitude: ", position.coords.longitude);
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    } else console.log("Geolocation is not supported by this browser.");
  };

  const getDeviceId = async () => {
    var uniqueId =
      "_" +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    if (!localStorage.getItem("deviceId"))
      localStorage.setItem("deviceId", uniqueId);

    console.log("deviceId: ", localStorage.getItem("deviceId"));
    console.log("unique id : ", uniqueId);

    setDeviceId(localStorage.getItem("deviceId").toString());
  };

  return (
    <CheckInPageWrapper>
      <img src={logo} alt="waterdrop" style={CheckInStyle.logo} />
      <Typography variant="h3" sx={CheckInStyle.color}>
        Attendance
      </Typography>
      <PrimaryButton
        onClick={() => {
          if (onDuty == true) setOnDuty(false);
          else setOnDuty(true);
        }}
        autoCapitalize="false"
        disabled={false}
        variant="outlined"
        style={{
          ...CheckInStyle.actionButtons,
          background: onDuty ? "green" : "red"
        }}
      >
        {onDuty ? "On Duty" : "Off Duty"}
      </PrimaryButton>

      <FormControl
        style={{ width: "200px", marginTop: "30px", marginBottom: "15px" }}
      >
        <InputLabel id="demo-simple-select-required-label">
          Plant Name
        </InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select"
          value={checkInPlant.name}
          label="Plant Name"
          renderOption={(option) =>
            currentlyCheckedInPlantId === option.id ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                {option.name}
                <CheckIcon
                  style={{
                    color: "green",
                    marginLeft: "5px",
                    marginBottom: "3px",
                  }}
                />
              </div>
            ) : (
              <div> {option.name} </div>
            )
          }
          onChange={(event) => {
            console.log("plants : ", checkInPlant);
            setCheckInPlant(event.target.value);
          }}
        >
          {plants
            ? plants.map((item) => (
                <MenuItem value={item}>{item.name}</MenuItem>
              ))
            : null}
        </Select>
      </FormControl>

      {/* <TextField
            id="select-plant-name"
            select
            label="Plant Name"
            helperText="Please select your plant"
            onChange={(event) => {
              console.log("plants : ", checkInPlant);
              setCheckInPlant(event.target.value);
            }}
            value={checkInPlant.name}
          >
            {plants.map((option) => (
              <MenuItem key={option.name} value={option}>
                {option.name}
              </MenuItem>
            ))}
          </TextField> */}

      <PrimaryButton
        onClick={() => {
          getDeviceInfo();
        }}
        autoCapitalize="false"
        // disabled={timeIn === "" || timeOut === "" || checkInPlant === {}}
        disabled={false}
        variant="outlined"
        style={CheckInStyle.actionButtons}
      >
        Share my location
      </PrimaryButton>

      <PrimaryButton
        onClick={() => {
          checkInUserfnc();
        }}
        autoCapitalize="false"
        disabled={
          checkInPlant.id === "" ||
          deviceId === "" ||
          location == null ||
          !onDuty ||
          checkInPlant.id === "none"
        }
        // disabled={ false}
        variant="outlined"
        style={CheckInStyle.actionButtons}
      >
        CheckIn
      </PrimaryButton>
      <PrimaryButton
        onClick={() => {
          continueWithoutCheckInButtonPressed();
        }}
        autoCapitalize="false"
        variant="outlined"
        style={CheckInStyle.actionButtons}
        disabled={location == null ? true : false}
      >
        {checkInPlant.id === "none"
          ? "Checkout From all plants"
          : "Continue without CheckIn"}
      </PrimaryButton>
    </CheckInPageWrapper>
  );
}
