import { Button, Dialog, DialogActions } from '@mui/material';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import useIsMobile from '../../utilities/customHooks/useIsMobile';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';

const StaticDateTimePickerComponent = ({
  openDialog,
  handleCloseDialog,
  selectedTime,
  handleTimePickerChange,
}: any) => {
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const [tempSelectedTime, setTempSelectedTime] = useState(selectedTime);

  const handleChangeTime = (newTime: any) => {
    setTempSelectedTime(newTime);
  };

  const handleOk = () => {
    handleTimePickerChange(tempSelectedTime); // Apply the selected time when Ok is clicked
    handleCloseDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticTimePicker
          value={selectedTime}
          onChange={handleChangeTime}
          ampm={false}
          slotProps={{
            actionBar: { actions: [] }, // Hides default "Ok" and "Cancel" buttons
          }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ...(isMobile && {
              minWidth: 'unset !important',
              '.MuiClock-root': {
                justifyContent: 'flex-start',
                marginLeft: '2rem',
              },
              '.MuiTimeClock-arrowSwitcher': {
                right: '30px',
              },
            }),
          }}
        />
      </LocalizationProvider>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Cancel</Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StaticDateTimePickerComponent;
