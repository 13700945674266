import React, { useEffect, useState } from "react";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import InputIcon from "@mui/icons-material/Input";
import LockClockOutlinedIcon from "@mui/icons-material/LockClockOutlined";
import { ITokenBody } from "../../Interfaces/tokenInterface";
import { getProfilePicUrl } from "../../services/profile";
import { getInitials } from "../../utilities/string.utils";
import OTPPopup from "./OTPPopup";
import useUserStore from "../../store/UserStore";
import { useNavigate } from "react-router-dom";
import { decodeToken, isJwtExpired } from "../../utils/jwt.utils";
import jwtDecode from "jwt-decode";
import { loginUsingAuthToken } from "../../services/Login";
import { toast } from "react-toastify";
import {
  getCachedLoginToken,
  addCachedLoginEntry,
} from "../../utilities/cachedLoginEntries.utils";
import { loginData } from "../../Interfaces/loginInterfaces";
import { getOTPForLogin } from "../../services/Login";
import { loginUsing2StepVerification } from "../../services/Login";
import { OTP_RESEND_TIMER } from "../../constants/constants";
import useLoginEntryStore from "../../store/loginEntryStore";

const styles = {
  heading: {
    fontSize: "16px",
  },
  subHeading: {
    fontSize: "12px",
    color: "#999999",
    display: "flex",
    flexDirection: "row",
    alingItems: "center",
    gap: "5px",
    margin: 0,
  },
  cancelIcon: {
    color: "#999999",
    marginLeft: "auto",
  },
  emptyAvatar: {
    background: "#183650",
    width: 40,
    height: 40,
    fontSize: 16,
  },
};

interface UserEntryProps {
  loginEntry: ITokenBody;
  handleRemoveEntry: any
}

const getOTP = async (decodedToken: ITokenBody) => {
  console.log(decodedToken);
  let userId = decodedToken.userId;
  let oneFactorAuthToken = getCachedLoginToken(userId || "");
  try {
    let result = await getOTPForLogin(oneFactorAuthToken || "");
    toast(result?.message);
  } catch (error) {
    toast.error("Some error in resend otp");
  }
};

const PersistentUserItem: React.FC<UserEntryProps> = ({
  loginEntry,
  handleRemoveEntry
}) => {
  const [showOtpPopup, setShowOtpPopup] = useState<boolean>(false);
  const [loggingIn, setLogginIn] = useState<boolean>(false);
  const [selectedUserEntry, setSelectedUserEntry] = useState<ITokenBody | null>(
    null
  );
  let navigate = useNavigate();
  const checkIfDecodedJwtIsExpired = (decodedToken: ITokenBody) => {
    if (decodedToken.exp) {
      const currentTimestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
      return decodedToken.exp < currentTimestamp; // Check if expiration time is in the past
    } else {
      return false;
    }
  };
  //@ts-ignore
  const { selectedLoginEntry, setSelectedLoginEntry } = useLoginEntryStore();

  const getUserImage = (userId: string) => {
    getProfilePicUrl(userId).then((data: any) => {
      if (data?.status === 200) {
        setUserImage(data.profilePic);
        return "";
      } else {
        return "";
      }
    });
    return "";
  };

  const [userImage, setUserImage] = useState<string>("");

  useEffect(() => {
    getUserImage(loginEntry.userId);
  }, []);

  const {
    setToken,
    setLanguage,
    setSideBar,
    setUserId,
    setUserName,
    setLandingPage,
    setAllowAction,
  } = useUserStore();

  const storeCurrentUserInfo = (data: loginData) => {
    const { role, userName, userId } = decodeToken(data?.token);
    localStorage.setItem("token", data?.token || "");
    localStorage.setItem("userid", userId); // currently used in birection controle components

    setToken(data?.token);
    setLanguage("English");
    setSideBar(data.layoutInfo.sideBar);
    setUserId(userId);
    setUserName(userName);


    setLandingPage(data.layoutInfo.landingPage);

    if (role === "Operator Group") {
      setAllowAction(false);
    } else {
      setAllowAction(true);
    }
  };

  const thingsToDoIfUserTokenAvailable = (data: loginData) => {
    storeCurrentUserInfo(data);
    const { userId } = decodeToken(data.token);
    addCachedLoginEntry(userId, data?.token || "");

    navigate(data.layoutInfo.landingPage, { replace: false });

  };

  const loginUsingAuthTokenId = async (tokenId: string) => {
    setLogginIn(true);
    const token = getCachedLoginToken(tokenId);
    if (token && !isJwtExpired(token)) {
      const decodedToken: ITokenBody = jwtDecode(token);
      console.log(decodedToken);
      if (decodedToken.tokenType === "userToken") {
        let data = await loginUsingAuthToken(token);
        thingsToDoIfUserTokenAvailable(data);
      } else {
        await getOTP(decodedToken);
        setShowOtpPopup(true);
      }
      setLogginIn(false);
    } else {
      // set focus on password field and fill the email field automatically !
      toast("Please enter your password again!");
      setLogginIn(false);
    }
  };

  async function handleOTPVerification(
    oneFactorAuthToken: string,
    otp: number
  ) {
    try {
      const data: loginData = await loginUsing2StepVerification(
        oneFactorAuthToken,
        otp
      );

      if (data?.token) {
        storeCurrentUserInfo(data);
        

        navigate(data.layoutInfo.landingPage, { replace: false });
      } else {
        toast(data.message);
      }
    } catch (error) {
      toast("Otp verification failed");
    }
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        gap={3}
        p={2}
        pr={0}
        borderRadius={2}
        sx={{
          minWidth: "280px",
          cursor: "pointer",
          pointerEvents: loggingIn ? "none" : "all",
          "&:hover": {
            background: "#EDF3F9",
            border: "1px solid #4188C61A",
          },
        }}
        onClick={() => {
          setSelectedUserEntry(loginEntry);
          setSelectedLoginEntry(loginEntry); // this is for storing selected login entry in useLoginEntryStore
          loginUsingAuthTokenId(loginEntry.userId);
        }}
      >
        <Avatar
          sx={styles.emptyAvatar} // Define your styles for 'emptyAvatar' here
          src={userImage}
        >
          {getInitials(loginEntry.userName || "", 2)}
        </Avatar>
        <Stack direction="column" gap={1}>
          <Box sx={styles.heading}>{loginEntry.userName}</Box>
          <Box sx={styles.subHeading}>
            <MailOutlineIcon sx={styles.subHeading} />{" "}
            <span>{loginEntry.email}</span>
          </Box>
          <Box sx={styles.subHeading}>
            <InputIcon sx={styles.subHeading} />{" "}
            <span>
              {moment(loginEntry.createdAt, "YYYY-MM-DDTHH:mm:ss:SSSZ").format(
                "DD-MM-YYYY | HH :mm a "
              )}
            </span>
          </Box>
          {checkIfDecodedJwtIsExpired(loginEntry) && (
            <Box sx={styles.subHeading}>
              <LockClockOutlinedIcon sx={styles.subHeading} />
              <span>{"Need to enter password"}</span>
            </Box>
          )}
        </Stack>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveEntry(loginEntry?.userId);
          }}
          sx={styles.cancelIcon} // Define your styles for 'cancelIcon' here
        >
          <CancelIcon fontSize="small" />
        </IconButton>
      </Stack>
      <OTPPopup
        showOtpPopup={showOtpPopup}
        onClose={() => {
          setShowOtpPopup(false);
        }}
        onResendOTP={async () => {
          let userId = selectedUserEntry?.userId;
          let oneFactorAuthToken = getCachedLoginToken(userId || "");
          if (oneFactorAuthToken) {
            let result = await getOTPForLogin(oneFactorAuthToken || "");
            toast(result?.message);
          }
        }}
        onSubmit={async (otp: number) => {
          let userId = selectedUserEntry?.userId;
          let oneFactorAuthToken = getCachedLoginToken(userId || "");
          if (oneFactorAuthToken) {
            await handleOTPVerification(oneFactorAuthToken, otp);
          }
        }}
        timerDurationSeconds={OTP_RESEND_TIMER}
      />
    </>
  );
};

export default PersistentUserItem;
