import React, { Suspense, ComponentType } from 'react';

const LoadingFallback = <div>Loading...</div>;

function withLazyLoading<P>(importFunc: () => Promise<{ default: ComponentType<P> }>, fallback: React.ReactNode = LoadingFallback) {
  const LazyComponent = React.lazy(importFunc);

  return (props: any) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
}

export default withLazyLoading;