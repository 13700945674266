import { Box, Button, Dialog, DialogTitle, IconButton, TextField, Toolbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback } from 'react';
const CommentDialogueBox = (props: any) => {
  const {
    openCommentDialogue,
    handleCloseCommentDialogueOutside,
    commentDialogueHeaderText,
    handleCloseCommentDialogue,
    handleOnChangeComment,
    addComment,
    errorTitle,
    setErrorTitle,
    errorComment,
    setErrorComment,
    handleAddComment,
    setAnchorElComment,
    setOpen,
    setAddComment,
    allComments,
    setAllComments,
    flocData,
    currentSampleIndex,
    imgId,
  } = props;

  const handleDialogClose = useCallback(() => {
    handleCloseCommentDialogueOutside(
      setAnchorElComment,
      setOpen,
      setAddComment,
      setErrorTitle,
      setErrorComment
    );
  }, [
    handleCloseCommentDialogueOutside,
    setAnchorElComment,
    setOpen,
    setAddComment,
    setErrorTitle,
    setErrorComment,
  ]);

  return (
    <Dialog
      open={openCommentDialogue}
      onClose={handleDialogClose}
      sx={{
        borderRadius: '12px',
        '.MuiDialog-paper': { borderRadius: '12px !important' },
      }}
      fullWidth={true}
      maxWidth="xs"
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: '12px',
          paddingRight: '20px',
        }}
      >
        <DialogTitle
          sx={{
            paddingLeft: '8px !important',
            color: '#000000',
            fontSize: '1rem',
          }}
        >
          {commentDialogueHeaderText}
        </DialogTitle>
        <IconButton
          edge="end"
          onClick={() =>
            handleCloseCommentDialogue(
              setAnchorElComment,
              setOpen,
              setAddComment,
              setErrorTitle,
              setErrorComment
            )
          }
        >
          <CloseIcon fontSize="small" sx={{ color: '#000000' }} />
        </IconButton>
      </Toolbar>
      <Box display="flex" flexDirection="column" sx={{ padding: '0px 20px', gap: '1rem' }}>
        <TextField
          id="outlined-basic"
          label="Title"
          variant="outlined"
          name="title"
          onChange={e => handleOnChangeComment(e, addComment, setAddComment)}
          value={addComment.title}
          error={errorTitle}
          onFocus={() => setErrorTitle(false)}
          helperText={
            errorTitle
              ? 'Title should not be less then 4 Characters and not more than 20 Characters'
              : ''
          }
          FormHelperTextProps={{
            style: { marginLeft: 0 }, // Override default style
          }}
        />
        <TextField
          id="outlined-basic"
          label="Comment"
          variant="outlined"
          name="comment"
          value={addComment.comment}
          onChange={e => handleOnChangeComment(e, addComment, setAddComment)}
          error={errorComment}
          helperText={errorComment ? 'Comments should be minimum 5 words long' : ''}
          FormHelperTextProps={{
            style: { marginLeft: 0 }, // Override default style
          }}
          onFocus={() => setErrorComment(false)}
        />
        <Button
          variant="contained"
          sx={{
            padding: '1rem',
            background: '#3271A9',
            marginBottom: '1rem',
          }}
          onClick={() => {
            handleAddComment(
              addComment,
              setErrorTitle,
              setErrorComment,
              setAllComments,
              setAddComment,
              allComments,
              flocData,
              currentSampleIndex,
              imgId,
              handleCloseCommentDialogue
            );
          }}
          disabled={!addComment.title || !addComment.comment}
        >
          Save Comment
        </Button>
      </Box>
    </Dialog>
  );
};

export default CommentDialogueBox;
