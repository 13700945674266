const styles: any = {
  hiddenComponent: {
    visibility: 'hidden',
  },
  visibleComponent: {
    visibility: 'visible',
  },
  displayNoneComponent: {
    display: 'none',
  },
  widgetHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    padding: '15px',
    color: '#193458',
    fontFace: 'Roboto',
    fontWeight: '500',
    overflow: 'hidden',
    fontSize: 'clamp(16px,1.1vw,25px)',
  },
};

export default styles;
