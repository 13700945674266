import axiosInstance from '../constants/axiosInstance';

interface PlantListAutoCompleteProps {
  workspaceId?: string,
  plant: string
}

const fetchPlants = async () => {
  try {
    const { data } = await axiosInstance().get('/plantManagement/getallPlants');
    return data?.body;
  } catch (err) {
    console.error('ERROR | fetchPlants | ', err);
  }
};

const deletePlant = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().delete(`/plantManagement/deletePlant?id=${plantId}`);
    return data;
  } catch (err) {
    console.error('ERROR | deletePlant | ', err);
  }
};

const uploadPlantImage = async (
  plantId: string,
  imageName: string,
  imageControlId: string,
  formData: any
) => {
  try {
    let response: any = axiosInstance().post(
      `/plants/uploadPlantImage?plantId=${plantId}&imageName=${imageName}&imageControlId=${imageControlId}`,
      formData
    );
    return response;
  } catch (e) {
    console.error('ERROR | upload image | ', e);
    return { data: { data: { message: 'something went wrong', status: 'error' } } };
  }
};

const fetchImageForPlant = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().post(`/plants/fetchImageForPlant?plantId=${plantId}`);
    return data;
  } catch (err) {
    console.error('ERROR | fetchImageForPlant | ', err);
  }
};

const updateImageData = async (
  imageId: string,
  imageName: string,
  imageControlId: string,
  url: string
) => {
  try {
    const { data } = await axiosInstance().post(`/plants/updateImageData`, {
      imageId,
      imageName,
      imageControlId,
      url,
    });
    return data;
  } catch (err) {
    console.error('ERROR | updateImageData | ', err);
  }
};
const getUploadedImages = async () => {
  try {
    const { data } = await axiosInstance().get('/plants/fetchPlantImages');
    return data;
  } catch (err) {
    console.error('ERROR | getUploadedImages | ', err);
  }
};
const updateImageForPlant = async (plantId: string, imageId: string) => {
  try {
    const { data } = await axiosInstance().post(`/plants/updateImageForPlant`, {
      plantId,
      imageId,
    });
    return data;
  } catch (err) {
    console.error('ERROR | updateImageForPlant | ', err);
  }
};

const getPlantListAutoComplete = async (plantData: PlantListAutoCompleteProps) => {
  try {
    const { data } = await axiosInstance().post(`/formula/plantManagement/getListOfPlantsAutocomplete`, );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export {
  fetchPlants,
  deletePlant,
  uploadPlantImage,
  fetchImageForPlant,
  updateImageData,
  getUploadedImages,
  updateImageForPlant,
  getPlantListAutoComplete
};
