export const Breakpoints = {
  values: {
    xs: 0,
    sm: 440,
    md: 1024,
    lg: 1280,
    xl: 1920,
    extraSm: 700,
    extraXs: 380,
    extraMd: 1100,
    extraXl: 1250,
  },
};

export const Spacing = [0, 4, 8, 12, 16, 20, 24, 28, 32, 64];
